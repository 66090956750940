<template>
  <div id="app" class="url" style="padding: 15px 0">
    <div>
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px">
          <el-page-header @back="goBack" content="用户管理"> </el-page-header>
        </el-col>
      </el-row>
    </div>
    <div style="margin:10px">
        <el-button
          size="medium"
          type="success"
          @click="addUser"
          >添加用户</el-button
        >
    </div>
    <div>
      <el-table
        border
        :data="userData.filter((data) => !search || data.act.toLowerCase().includes(search.toLowerCase()))"
        max-height="800px"
      >
        <el-table-column prop="act" label="登录账号" width="auto">
        </el-table-column>
        <el-table-column prop="nm" label="姓名" width="150px">
        </el-table-column>
        <el-table-column prop="dis" label="账号类型" width="150px">
           <template slot-scope="scope">
              <span>{{ scope.row.dis == 0 ? "普通用户" : "调度用户" }}</span>
            </template>
        </el-table-column>
         <el-table-column prop="pnm" label="所属组织" width="150px">
        </el-table-column>
         <el-table-column prop="parentName" label="状态" width="150px">
        </el-table-column>
         <el-table-column prop="parentName" label="更新时间" width="150px">
        </el-table-column>

        <el-table-column align="center" width="auto">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="medium"
              placeholder="输入组织名称搜索"
            />
          </template>
          <template slot-scope="scope">
            <el-button
              size="medium"
              type="primary"
              @click="addUser(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="medium"
              type="danger"
              @click="delUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
     <div>
      <el-dialog title="添加用户" 
        @close="closeUserDialog('userformData')"
       :visible.sync="userDialogStatus"
        width="50%">
         <el-form ref="userformData" :model="userformData" label-width="100px" :rules="userRules">
           <el-row :gutter="12">
            <el-col :span="8">
              <el-form-item label="上级组织" prop="cid">
                <el-select v-model="userformData.cid" @change="filterRole">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="12">
            <el-col :span="8">
              <el-form-item label="登录账号" prop="name">
                <el-input v-model="userformData.account"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :push="6">
              <el-form-item label="用户姓名"  prop="account" >
                <el-input v-model="userformData.name" :disabled="isEdit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="12" :hidden="isEdit">
            <el-col :span="8">
              <el-form-item label="登录密码" prop="encryptPwd">
                <el-input v-model="userformData.encryptPwd"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :push="6">
              <el-form-item label="确认密码" prop="encryptPwd">
                <el-input v-model="userformData.encryptPwd"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="12">
            <el-col :span="8" >
              <el-form-item label="主账户类型" prop="dispatcher">
                 <el-select v-model="userformData.dispatcher">
                  <el-option
                    v-for="item in useroptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" :push="6" >
               <el-form-item label="权限" prop="roleId">
                <el-select v-model="userformData.roleId" >
                  <el-option
                    v-for="item in privilegeData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :span="6" >
              <el-form-item>
                <el-button type="primary" @click="submitForm('userformData')">添加</el-button>
                <el-button @click="closeUserDialog('userformData')">关闭</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import dataStorage from '@/../utils/dataStorage.js'
import BaseData from '@/assets/config/BaseData.js';
import axios from "axios";


export default {
  data() {
    return {
      isEdit:false,
      userDialogStatus:false,
      userformData:{
        jsession:dataStorage.getBaseData("videoJsession"),
        account:"",       // 账号
        roleId:"",        // 权限
        cid:"",           // 所属组织id

        encryptPwd:"000000",
        // password:"000000",
        name:"",           // 用户姓名
        dispatcher:0,     // 用户类型
        id:"",              // 用户id
      },
      useroptions:[
        {
          name:"普通用户",
          id:0
        },{
          name:"调度用户",
          id:1
        },
      ],
      options: [],
      userRules:{
        cid: [
          { required: true, message: '请选择组织名称', trigger: 'change' }
        ],
      },

      userData: [],
      privilegeData:[],    // 权限展示
      privilegeDataText:[],// 权限暂存
      search: "",
    };
  },
  created() {
    this.findUser()
    this.findCompany()
    this.findUserRole()
  },
  methods: {
    filterRole(e){
      this.privilegeData = this.privilegeDataText.filter( data => data.company.id === e)
    },
    submitForm(userformData){
      this.$refs[userformData].validate(value => {
        if (value) {
           axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_mergeUserAccount.action", {
          params: this.userformData,
        })
        .then((res) => {
          console.log(res);
           this.$refs[userformData].resetFields();
           this.userDialogStatus=false
           if (res.data.result == 0) {
              this.$message.success("添加成功")
              this.findUser()
           }else{
              this.$message.error("添加失败")
           }
        });
        }
      })
    },
    addUser(row){
      this.userDialogStatus=true
      if (row.id) {
        console.log(row);
        this.filterRole(row.pid)  // 查权限表
        

        this.userformData.account = row.act  // 账号
        this.userformData.cid = row.pid       // 所属组织

        this.userformData.roleId = row.rid    // 权限
        this.userformData.id = row.id       // 当前用户id

        this.userformData.name = row.nm       // 当前用户id
        this.userformData.dispatcher = row.dis       // 当前用户id
        
      }else {
        console.log(222);
      }
    },
    closeUserDialog(userformData){
      this.$refs[userformData].resetFields();
      // this.userformData
      this.userDialogStatus=false
    },
    findCompany() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_findCompany.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
           this.options  = JSON.parse(JSON.stringify(res.data.infos));
        });
    },
    findUser() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_findUserAccount.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
           this.userData = res.data.infos
        });
    },
     findUserRole() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_findUserRole.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
          //  this.privilegeDataText = res.data.role.filter( data => data.company.id === row)
           this.privilegeDataText = res.data.role
        });
    },
    delUser(row){
      this.$confirm('此操作将永久删除此用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios
        .get( "http://39.97.231.64:8088/StandardApiAction_deleteUserAccount.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
            id:row.id,
          },
        })
        .then((res) => {
           if (res.data.result == 0) {
            this.$message.success("删除成功")
            this.findUser()
           }
        });
      }).catch(() => {
          this.$message.info("取消删除")
      });
    },
    
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    
  },
};
</script>

<style lang="scss" scope>
</style>
